import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";
import CwChainModule from "@/store/modules/CwChainModule";
config.rawError = true;
var store = createStore({
    modules: {
        AuthModule: AuthModule,
        BodyModule: BodyModule,
        BreadcrumbsModule: BreadcrumbsModule,
        ConfigModule: ConfigModule,
        CwChainModule: CwChainModule
    }
});
export default store;
