// https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
export function getElementMatches(element, selector) {
    var p = Element.prototype;
    var f = p.matches ||
        p.webkitMatchesSelector;
    if (element && element.tagName) {
        return f.call(element, selector);
    }
    else {
        return false;
    }
}
