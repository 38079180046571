import Theme from "../_Theme";
import { getViewPort } from "./dom-helpers/_getViewPort";
import { getObjectPropertyValueByKey } from "./types-helpers/_getObjectPropertyValueByKey";
import { toJSON } from "./types-helpers/_toJSON";
export function getAttributeValueByBreakpoint(incomingAttr) {
    var value = toJSON(incomingAttr);
    if (typeof value !== 'object') {
        return incomingAttr;
    }
    var width = getViewPort().width;
    var resultKey;
    var resultBreakpoint = -1;
    var breakpoint;
    for (var key in value) {
        if (key === 'default') {
            breakpoint = 0;
        }
        else {
            breakpoint = Theme.getBreakpoint(key) ? Theme.getBreakpoint(key) : parseInt(key);
        }
        if (breakpoint <= width && breakpoint > resultBreakpoint) {
            resultKey = key;
            resultBreakpoint = breakpoint;
        }
    }
    return resultKey ? getObjectPropertyValueByKey(value, resultKey) : value;
}
