export function getElementActualCss(el, prop, cache) {
    var css = "";
    if (!el.getAttribute("kt-hidden-" + prop) || cache === false) {
        var value = void 0;
        // the element is hidden so:
        // making the el block so we can meassure its height but still be hidden
        css = el.style.cssText;
        el.style.cssText =
            "position: absolute; visibility: hidden; display: block;";
        if (prop === "width") {
            value = el.offsetWidth;
        }
        else if (prop === "height") {
            value = el.offsetHeight;
        }
        el.style.cssText = css;
        // store it in cache
        if (value !== undefined) {
            el.setAttribute("kt-hidden-" + prop, value.toString());
            return parseFloat(value.toString());
        }
        return 0;
    }
    else {
        // store it in cache
        var attributeValue = el.getAttribute("kt-hidden-" + prop);
        if (attributeValue || attributeValue === '0') {
            return parseFloat(attributeValue);
        }
    }
}
export function getElementActualHeight(el) {
    return getElementActualCss(el, "height", false);
}
export function getElementActualWidth(el, cache) {
    return getElementActualCss(el, "width", false);
}
