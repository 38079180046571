import { __decorate, __extends, __metadata } from "tslib";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
var AuthModule = /** @class */ (function (_super) {
    __extends(AuthModule, _super);
    function AuthModule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.errors = [];
        _this.user = {};
        _this.isAuthenticated = !!JwtService.getToken();
        return _this;
    }
    Object.defineProperty(AuthModule.prototype, "currentUser", {
        /**
         * Get current user object
         * @returns User
         */
        get: function () {
            return this.user;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthModule.prototype, "isUserAuthenticated", {
        /**
         * Verify user authentication
         * @returns boolean
         */
        get: function () {
            return this.isAuthenticated;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthModule.prototype, "getErrors", {
        /**
         * Get authentification errors
         * @returns array
         */
        get: function () {
            return this.errors;
        },
        enumerable: false,
        configurable: true
    });
    AuthModule.prototype[_a = Mutations.SET_ERROR] = function (error) {
        this.errors = error;
    };
    AuthModule.prototype[_b = Mutations.SET_AUTH] = function (user) {
        this.isAuthenticated = true;
        this.user = user;
        this.errors = [];
        JwtService.saveToken(this.user.token);
    };
    AuthModule.prototype[_c = Mutations.SET_USER] = function (user) {
        this.user = user;
    };
    AuthModule.prototype[_d = Mutations.SET_PASSWORD] = function (password) {
        this.user.password = password;
    };
    AuthModule.prototype[_e = Mutations.PURGE_AUTH] = function () {
        this.isAuthenticated = false;
        this.user = {};
        this.errors = [];
        JwtService.destroyToken();
    };
    AuthModule.prototype[_f = Actions.LOGIN] = function (credentials) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.post("login", credentials)
                .then(function (_m) {
                var data = _m.data;
                _this.context.commit(Mutations.SET_AUTH, data);
                resolve();
            })
                .catch(function (_m) {
                var response = _m.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    AuthModule.prototype[_g = Actions.LOGOUT] = function () {
        this.context.commit(Mutations.PURGE_AUTH);
    };
    AuthModule.prototype[_h = Actions.REGISTER] = function (credentials) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.post("registration", credentials)
                .then(function (_m) {
                var data = _m.data;
                _this.context.commit(Mutations.SET_AUTH, data);
                resolve();
            })
                .catch(function (_m) {
                var response = _m.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    AuthModule.prototype[_j = Actions.FORGOT_PASSWORD] = function (payload) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.post("forgot_password", payload)
                .then(function (_m) {
                var data = _m.data;
                _this.context.commit(Mutations.SET_AUTH, data);
                resolve();
            })
                .catch(function (_m) {
                var response = _m.response;
                console.log(response.data.errors);
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    AuthModule.prototype[_k = Actions.VERIFY_AUTH] = function () {
        var _this = this;
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("verify")
                .then(function (_m) {
                var data = _m.data;
                _this.context.commit(Mutations.SET_AUTH, data);
            })
                .catch(function (_m) {
                var response = _m.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
        }
        else {
            this.context.commit(Mutations.PURGE_AUTH);
        }
    };
    AuthModule.prototype[_l = Actions.UPDATE_USER] = function (payload) {
        var _this = this;
        ApiService.setHeader();
        return new Promise(function (resolve, reject) {
            ApiService.post("update_user", payload)
                .then(function (_m) {
                var data = _m.data;
                _this.context.commit(Mutations.SET_USER, data);
                resolve();
            })
                .catch(function (_m) {
                var response = _m.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _a, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _b, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _c, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _d, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _e, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _f, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _g, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _h, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _j, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _k, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], AuthModule.prototype, _l, null);
    AuthModule = __decorate([
        Module
    ], AuthModule);
    return AuthModule;
}(VuexModule));
export default AuthModule;
