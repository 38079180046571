var Actions;
(function (Actions) {
    // action types
    Actions["ADD_BODY_CLASSNAME"] = "addBodyClassName";
    Actions["REMOVE_BODY_CLASSNAME"] = "removeBodyClassName";
    Actions["ADD_BODY_ATTRIBUTE"] = "addBodyAttribute";
    Actions["REMOVE_BODY_ATTRIBUTE"] = "removeBodyAttribute";
    Actions["ADD_CLASSNAME"] = "addClassName";
    Actions["VERIFY_AUTH"] = "verifyAuth";
    Actions["LOGIN"] = "login";
    Actions["LOGOUT"] = "logout";
    Actions["REGISTER"] = "register";
    Actions["UPDATE_USER"] = "updateUser";
    Actions["FORGOT_PASSWORD"] = "forgotPassword";
    Actions["SET_BREADCRUMB_ACTION"] = "setBreadcrumbAction";
    Actions["FETCH_PAGE_DATA"] = "fetchPageData";
    Actions["FETCH_BLOCK_BY_HASH"] = "fetchBlockByHash";
    Actions["FETCH_INSIGHTS"] = "fetchInsights";
    Actions["FETCH_STATS"] = "fetchStats";
    Actions["FETCH_SERVICE_STATUS"] = "fetchServiceStatus";
    Actions["FETCH_STATUS"] = "fetchStatus";
})(Actions || (Actions = {}));
var Mutations;
(function (Mutations) {
    // mutation types
    Mutations["SET_CLASSNAME_BY_POSITION"] = "appendBreadcrumb";
    Mutations["PURGE_AUTH"] = "logOut";
    Mutations["SET_AUTH"] = "setAuth";
    Mutations["SET_USER"] = "setUser";
    Mutations["SET_PASSWORD"] = "setPassword";
    Mutations["SET_ERROR"] = "setError";
    Mutations["SET_BREADCRUMB_MUTATION"] = "setBreadcrumbMutation";
    Mutations["SET_LAYOUT_CONFIG"] = "setLayoutConfig";
    Mutations["RESET_LAYOUT_CONFIG"] = "resetLayoutConfig";
    Mutations["OVERRIDE_LAYOUT_CONFIG"] = "overrideLayoutConfig";
    Mutations["OVERRIDE_PAGE_LAYOUT_CONFIG"] = "overridePageLayoutConfig";
    Mutations["RESET_INSIGHTS_BLOCKS"] = "resetInsightsBlocks";
    Mutations["SET_PAGE_DATA"] = "setPageData";
    Mutations["SET_ALL_BLOCKS_PENDING_NUMBER"] = "setAllBlocksPendingNumber";
    Mutations["SET_ALL_BLOCKS_STATUS"] = "setAllBlocksStatus";
    Mutations["SET_BLOCK_INFO"] = "setBlockInfo";
    Mutations["SET_INSIGHTS_BLOCKS"] = "setInsightsBlocks";
    Mutations["SET_LINKS_LENGTH"] = "setLinksLngth";
    Mutations["SET_NUMBER_OF_PENDING_BLOCKS"] = "setNumberOfPendingBlocks";
    Mutations["SET_STATS"] = "setStats";
    Mutations["SET_SERVICE_STATUS"] = "setServiceStatus";
    Mutations["SET_STATUS"] = "setStatus";
})(Mutations || (Mutations = {}));
export { Actions, Mutations };
