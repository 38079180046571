import { createRouter, createWebHashHistory } from "vue-router";
import store from "@/store";
import { Mutations } from "@/store/enums/StoreEnums";
var routes = [
    {
        path: "/",
        redirect: "/latest",
        component: function () { return import("@/layout/Layout.vue"); },
        children: [
            {
                path: "/latest",
                name: "latest",
                component: function () { return import("@/views/Latest.vue"); }
            },
            {
                path: "/blocks",
                name: "blocks",
                component: function () { return import("@/views/pages/blocks/TableVue.vue"); }
            },
            {
                path: "/blocks/:hash",
                name: "blockInfo",
                props: true,
                component: function () { return import("@/views/pages/info/BlockInfo.vue"); }
            },
            {
                path: "/stats",
                name: "stats",
                component: function () { return import("@/views/pages/stats/Stats.vue"); }
            },
            {
                path: "/status",
                name: "status",
                component: function () { return import("@/views/pages/status/Status.vue"); }
            }
        ]
    },
    {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: function () { return import("@/views/error/Error404.vue"); }
    },
    {
        // the 404 route, when none of the above matches
        path: "/500",
        name: "500",
        component: function () { return import("@/views/error/Error500.vue"); }
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: "/404"
    }
];
var router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});
router.beforeEach(function () {
    // reset config to initial state
    store.commit(Mutations.RESET_LAYOUT_CONFIG);
    // Scroll page to top on every route change
    setTimeout(function () {
        window.scrollTo(0, 0);
    }, 100);
});
export default router;
