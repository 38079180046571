import { getElementMatches } from "./_getElementMatches";
export function getElementChildren(element, selector) {
    if (!element || !element.childNodes) {
        return null;
    }
    var result = [];
    for (var i = 0; i < element.childNodes.length; i++) {
        var child = element.childNodes[i];
        // child.nodeType == 1 => Element, Text, Comment, ProcessingInstruction, CDATASection, EntityReference
        if (child.nodeType === 1 &&
            getElementMatches(child, selector)) {
            result.push(child);
        }
    }
    return result;
}
