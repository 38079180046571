export function getElementIndex(element) {
    if (element.parentNode) {
        var c = element.parentNode.children;
        for (var i = 0; i < c.length; i++) {
            if (c[i] === element)
                return i;
        }
    }
    return -1;
}
