export function toJSON(value) {
    if (typeof value !== 'string') {
        return value;
    }
    if (!value) {
        return undefined;
    }
    // ("'" => "\"");
    var result = value.toString().split('').map(function (el) { return el !== "'" ? el : "\""; }).join('');
    var jsonStr = result.replace(/(\w+:)|(\w+ :)/g, function (matched) {
        return '"' + matched.substring(0, matched.length - 1) + '":';
    });
    try {
        return JSON.parse(jsonStr);
    }
    catch (_a) {
        return undefined;
    }
}
