var Theme = /** @class */ (function () {
    function Theme() {
    }
    Theme.getBreakpoint = function (key) {
        var map = new Map(Object.entries(Theme.config.breakpoints));
        return map.get(key);
    };
    Theme.init = function (config) {
        Theme.config = config || defaultThemeConfig;
    };
    return Theme;
}());
export default Theme;
export var defaultThemeConfig = {
    breakpoints: {
        sm: 576,
        md: 768,
        lg: 992,
        xl: 1200,
        xxl: 1400,
    },
};
