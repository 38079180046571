var config = {
    themeName: "CW Chain",
    themeVersion: "0.1.0",
    demo: "cwChain",
    main: {
        type: "default",
        primaryColor: "#009EF7",
        logo: {
            dark: "media/logos/148x43_whiteupanddown.png",
            light: "media/logos/148x43_whiteupanddown.png",
        },
    },
    loader: {
        logo: "media/logos/loader-cw.gif",
        display: true,
        type: "default",
    },
    scrollTop: {
        display: true,
    },
    header: {
        display: true,
        menuIcon: "font",
        width: "fluid",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    toolbar: {
        display: true,
        width: "fluid",
        fixed: {
            desktop: true,
            tabletAndMobile: true,
        },
    },
    aside: {
        display: true,
        theme: "dark",
        fixed: true,
        menuIcon: "svg",
        minimized: false,
        minimize: true,
        hoverable: true,
    },
    content: {
        width: "fixed",
    },
    footer: {
        width: "fluid",
    },
};
export default config;
