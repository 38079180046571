import { __decorate, __extends, __metadata } from "tslib";
import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import router from "@/router";
var CwStageModule = /** @class */ (function (_super) {
    __extends(CwStageModule, _super);
    function CwStageModule() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.insightsBlocks = [];
        _this.errors = [];
        _this.pending = 0;
        _this.status = false;
        _this.data = {};
        _this.linksLength = "";
        _this.blockPending = 0;
        _this.block = {};
        _this.stats = {};
        //service status variables
        _this.serviceStatus = {};
        return _this;
    }
    Object.defineProperty(CwStageModule.prototype, "isOnline", {
        //
        // --- GETTERS ---
        //
        //
        // INSIGHTS
        //
        /**
         * Get chain linkn status
         * @returns boolean
         */
        get: function () {
            return this.status;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "latestBlocks", {
        /**
         * Get latest blocks
         * @returns array
         */
        get: function () {
            return this.insightsBlocks;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "pendingBlocks", {
        /**
         * Get pending blocks
         * @returns number
         */
        get: function () {
            return this.pending;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "paglinksArrayLength", {
        //
        // BLOCKS
        //
        /**
         * Get all blocks response status
         * @returns object
         */
        get: function () {
            return this.linksLength;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "allBlocksPendingNumber", {
        /**
         * Get pending blocks response
         * @returns object
         */
        get: function () {
            return this.blockPending;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "allBlocksData", {
        /**
         * Get all blocks
         * @returns object
         */
        get: function () {
            return this.data;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "blockInfo", {
        /**
         * Get block info
         * @returns object
         */
        get: function () {
            return this.block;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "statsInfo", {
        //
        // STATS
        //
        /**
         * Get stats info
         * @returns object
         */
        get: function () {
            return this.stats;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CwStageModule.prototype, "serviceStatusInfo", {
        //
        // SERVICE STATUS
        //
        /**
         * Get service status info
         * @returns object
         */
        get: function () {
            return this.serviceStatus;
        },
        enumerable: false,
        configurable: true
    });
    //
    // --- MUTATIONS ---
    //
    //
    // INSIGHTS
    //
    CwStageModule.prototype[_a = Mutations.SET_INSIGHTS_BLOCKS] = function (blocks) {
        for (var i = 0; i < blocks.length; i++) {
            var item = blocks[i];
            this.insightsBlocks.push(item);
        }
    };
    CwStageModule.prototype[_b = Mutations.RESET_INSIGHTS_BLOCKS] = function () {
        var insightsBlocks = [];
        this.insightsBlocks = insightsBlocks;
    };
    CwStageModule.prototype[_c = Mutations.SET_NUMBER_OF_PENDING_BLOCKS] = function (payload) {
        this.pending = payload;
    };
    CwStageModule.prototype[_d = Mutations.SET_ERROR] = function (error) {
        this.errors = error;
    };
    CwStageModule.prototype[_e = Mutations.SET_STATUS] = function (status) {
        this.status = status;
    };
    //
    // BLOCKS MUTATIONS
    //
    CwStageModule.prototype[_f = Mutations.SET_LINKS_LENGTH] = function (payload) {
        this.linksLength = payload;
    };
    CwStageModule.prototype[_g = Mutations.SET_ALL_BLOCKS_PENDING_NUMBER] = function (payload) {
        this.blockPending = payload;
    };
    CwStageModule.prototype[_h = Mutations.SET_PAGE_DATA] = function (payload) {
        // DEEP COPY
        var temp = JSON.parse(JSON.stringify(payload));
        this.data = temp;
    };
    CwStageModule.prototype[_j = Mutations.SET_BLOCK_INFO] = function (payload) {
        // DEEP COPY
        var temp = JSON.parse(JSON.stringify(payload));
        this.block = temp;
    };
    //
    // STATS
    //
    CwStageModule.prototype[_k = Mutations.SET_STATS] = function (payload) {
        // DEEP COPY
        var temp = JSON.parse(JSON.stringify(payload));
        this.stats = temp;
    };
    //
    // SERVICE STATUS
    //
    CwStageModule.prototype[_l = Mutations.SET_SERVICE_STATUS] = function (payload) {
        // DEEP COPY
        var temp = JSON.parse(JSON.stringify(payload));
        this.serviceStatus = temp;
    };
    //
    // --- ACTIONS ---
    //
    //
    // INSIGHTS
    //
    CwStageModule.prototype[_m = Actions.FETCH_INSIGHTS] = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.get("")
                .then(function (response) {
                if (response.status == 200) {
                    if (response.data.status == "ok") {
                        _this.context.commit(Mutations.SET_STATUS, true);
                        _this.context.commit(Mutations.SET_INSIGHTS_BLOCKS, response.data.blocks);
                        _this.context.commit(Mutations.SET_NUMBER_OF_PENDING_BLOCKS, response.data.stats.pending);
                    }
                    else {
                        _this.context.commit(Mutations.SET_STATUS, false);
                    }
                    resolve();
                }
                else {
                    router.push("500");
                }
            })
                .catch(function (_s) {
                var response = _s.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    //
    // BLOCKS
    //
    CwStageModule.prototype[_o = Actions.FETCH_PAGE_DATA] = function (payload) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var slug;
            if (payload == (null || undefined)) {
                slug = "";
            }
            else {
                slug = "?page=" + payload;
            }
            ApiService.get("blocks", slug)
                .then(function (response) {
                if (response.status == 200) {
                    // console.log("ACTION");
                    // console.log(response.data);
                    _this.context.commit(Mutations.SET_PAGE_DATA, response.data.blocks);
                    resolve();
                }
                else {
                    router.push("500");
                }
            })
                .catch(function (_s) {
                var response = _s.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    CwStageModule.prototype[_p = Actions.FETCH_BLOCK_BY_HASH] = function (hash) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.get("blocks", hash)
                .then(function (response) {
                // console.log(response.data);
                if (response.status == 200) {
                    _this.context.commit(Mutations.SET_BLOCK_INFO, response.data.block);
                    resolve();
                }
                else {
                    router.push("500");
                }
            })
                .catch(function (_s) {
                var response = _s.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    //
    // STATS
    //
    CwStageModule.prototype[_q = Actions.FETCH_STATS] = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            ApiService.get("stats")
                .then(function (response) {
                if (response.status == 200) {
                    // console.log(response.data);
                    _this.context.commit(Mutations.SET_STATS, response.data);
                    resolve();
                }
                else {
                    router.push("500");
                }
            })
                .catch(function (_s) {
                var response = _s.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    //
    // STATUS
    //
    CwStageModule.prototype[_r = Actions.FETCH_SERVICE_STATUS] = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            // let lastPage = 0;
            var res = {
                totalBlocks: 0,
                // lastBlock: {},
                pendingBlocks: 0,
                syncedBlocks: 0
            };
            ApiService.get("blocks")
                .then(function (response) {
                if (response.status == 200) {
                    // console.log(response.data);
                    res.pendingBlocks = response.data.stats.pending;
                    res.totalBlocks = response.data.blocks.total;
                    res.syncedBlocks = res.totalBlocks - res.pendingBlocks;
                    resolve();
                    _this.context.commit(Mutations.SET_SERVICE_STATUS, res);
                }
                else {
                    router.push("500");
                }
            })
                .catch(function (_s) {
                var response = _s.response;
                _this.context.commit(Mutations.SET_ERROR, response.data.errors);
                reject();
            });
        });
    };
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_s = typeof Array !== "undefined" && Array) === "function" ? _s : Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _a, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _b, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _c, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _d, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _e, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _f, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _g, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _h, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _j, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _k, null);
    __decorate([
        Mutation,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _l, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _m, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _o, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _p, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _q, null);
    __decorate([
        Action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], CwStageModule.prototype, _r, null);
    CwStageModule = __decorate([
        Module
    ], CwStageModule);
    return CwStageModule;
}(VuexModule));
export default CwStageModule;
