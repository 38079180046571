import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
/**
 * @description service to call HTTP request via Axios
 */
var ApiService = /** @class */ (function () {
    function ApiService() {
    }
    /**
     * @description initialize vue axios
     */
    ApiService.init = function (app) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        ApiService.vueInstance.axios.defaults.baseURL =
            "https://api.copyrightsworld.com/v1/insights";
    };
    /**
     * @description set the default HTTP request headers
     */
    ApiService.setHeader = function () {
        ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = "Token " + JwtService.getToken();
    };
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.query = function (resource, params) {
        return ApiService.vueInstance.axios.get(resource, params).catch(function (error) {
            throw new Error("[KT] ApiService " + error);
        });
    };
    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    ApiService.get = function (resource, slug) {
        if (slug === void 0) { slug = ""; }
        return ApiService.vueInstance.axios
            .get(resource + "/" + slug)
            .catch(function (error) {
            throw new Error("[KT] ApiService " + error);
        });
    };
    /**
     * @description send the GET HTTP request for pagination
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    ApiService.getPaginated = function (resource, slug) {
        console.log("ApiService.ts: " + ("" + resource + slug));
        return ApiService.vueInstance.axios
            .get("" + resource + slug)
            .catch(function (error) {
            throw new Error("[KT] ApiService " + error);
        });
    };
    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.post = function (resource, params) {
        return ApiService.vueInstance.axios.post("" + resource, params);
    };
    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.update = function (resource, slug, params) {
        return ApiService.vueInstance.axios.put(resource + "/" + slug, params);
    };
    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    ApiService.put = function (resource, params) {
        return ApiService.vueInstance.axios.put("" + resource, params);
    };
    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    ApiService.delete = function (resource) {
        return ApiService.vueInstance.axios.delete(resource).catch(function (error) {
            throw new Error("[RWV] ApiService " + error);
        });
    };
    return ApiService;
}());
export default ApiService;
