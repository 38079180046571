import { defineComponent, onMounted } from "vue";
import { useStore } from "vuex";
import { Mutations } from "@/store/enums/StoreEnums";
export default defineComponent({
    name: "app",
    setup: function () {
        var store = useStore();
        onMounted(function () {
            store.commit(Mutations.OVERRIDE_LAYOUT_CONFIG);
        });
    }
});
